import React from "react";
import '../styles/Home.css';

const CasesProductOptions = () => {
    return (
        <div className="cases_sec">
            <div className="containers">
                <div className="cases_sec_inner">
                    <div className="_cases_img">
                        <img src="images/_flower.png" alt="" />
                    </div>
                    <div className="_cases_content">
                        <h2>Open Channels Flow Measurement</h2>
                        <p>Monitoring of water level is of great significance in view of depleting water
                        resources. Our automatic water level recording system is extensively used
                        for monitoring water table in the ground. A variety of solutions are available
                        ranging from ultrasonic to radar based sensors for use by industry and for
                        a state wide monitoring of hundreds of boreholes from a central location.
                        The system can be used for monitoring water level in an open channel like
                        river, lake or reservoir by lowering the sensor in a gauge well</p>
                        <div className="option_sec">
                            <h2>Product Options</h2>
                            <div className="option_slides_main">
                                <div className="option_slides">
                                    <span className="option_box"><img src="images/case_1.png" alt="" /></span>
                                    <h2>Telemetric Water Level Sensors</h2>
                                    <span className="option_arrow"><i className="fa-solid fa-chevron-right"></i></span>
                                </div>
                                <div className="option_slides">
                                    <span className="option_box"><img src="images/case_1.png" alt="" /></span>
                                    <h2>Radar Based Water Level Sensors</h2>
                                    <span className="option_arrow"><i className="fa-solid fa-chevron-right"></i></span>
                                </div>
                                <div className="option_slides">
                                    <span className="option_box"><img src="images/case_1.png" alt="" /></span>
                                    <h2>Ultrasonic Water Level Sensors</h2>
                                    <span className="option_arrow"><i className="fa-solid fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CasesProductOptions;