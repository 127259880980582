import React from "react";
import OtherBanner from "../component/other-banner";
import Layout from "../component/layout";
import CasesProductOptions from "../component/cases-product-options";
import WhyClientPrefer from "../component/why-client-prefer";

const Cases = () => {
    const bannerImg = 'images/banner1.png';
    const bannerTitle = "Use Cases";

    return (
        <Layout>
            <OtherBanner bannerImg={bannerImg} bannerTitle={bannerTitle} />
            <CasesProductOptions />
            <WhyClientPrefer />
        </Layout>
    );
}

export default Cases;