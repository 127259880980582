import React from "react";
import OtherBanner from "../component/other-banner";
import CareerForm from  '../component/career-form';
import Layout from "../component/layout";

const Career = () => {
    const bannerImg = 'images/banner1.png';
    const bannerTitle = "Join Us";

    return (
        <Layout>
            <OtherBanner bannerImg={bannerImg} bannerTitle={bannerTitle} />
            <CareerForm />
        </Layout>
    );
}

export default Career;