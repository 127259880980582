import React, { useEffect, useState } from 'react';
import '../styles/Home.css';
import Layout from '../component/layout';
import HomeBanner from '../component/home-banner';
import HomeAboutVideo from '../component/home-about-video';
import WhatWeDo from '../component/what-we-do';
import OurOffering from '../component/our-offering';
import UseCases from '../component/use-cases';
import WhyEE from '../component/why-ee';
import OurProduct from '../component/our-product';
import DataVisualization from '../component/data-visualization';
import Testimonial from '../component/testimonials';
import OurClient from '../component/our-client';
import PreLoader from '../component/preloader';


const Home = () => {
  const [loadedData, setLoadedData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}api/home`); // Replace with your actual API endpoint
        const data = await res.json();
        setLoadedData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      {loadedData ? (
        <>
          <HomeBanner banners={loadedData.banners} />
          <HomeAboutVideo />
          <WhatWeDo />
          <OurOffering />
          <UseCases useCases={loadedData.useCases} />
          <WhyEE why={loadedData.why} />
          <OurProduct products={loadedData.prodCatUpdated} />
          <DataVisualization />
          <Testimonial testimonials={loadedData} />
          <OurClient clients={loadedData} />
        </>
      ) : (
        <PreLoader />
      )}
    </Layout>
  );
};

export default Home;
