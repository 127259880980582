import React from "react";
import "../styles/Home.css";

const AwardsRecognition = (gallery) => {
    const heading1 = gallery.gallery[0].heading1;
    const description = gallery.gallery[0].description;

    return (
        <div className="awards_sec">
            <div className="containers">
                <h2 className="gallery_h2">{ heading1 }</h2>
                <div className="awards_sec_inner">
                    <div className="awards_img_sec"><img src="images/awards_img.png" alt="" /></div>
                    <div className="awards_content_sec">
                        <div dangerouslySetInnerHTML={{ __html: description }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AwardsRecognition;