import React, { useState, useEffect } from "react";
import OtherBanner from "../component/other-banner";
import UseCaseModel from "../component/use-case-model";
import Layout from "../component/layout";
import PreLoader from "../component/preloader";

const UseCases = () => {
    const bannerImg = 'images/banner1.png';
    const bannerTitle = "Use Cases";
    const [loadedData, setLoadedData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}api/use-cases`); // Replace with your actual API endpoint
            const data = await res.json();
            setLoadedData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchData();
    }, []);

    return (
        <Layout>
            {loadedData ? (
                <>
                    <OtherBanner bannerImg={bannerImg} bannerTitle={bannerTitle} />
                    <UseCaseModel useCase={loadedData} />
                </>
            ) : (
                <PreLoader />
            )};
        </Layout>
    );
}

export default UseCases;